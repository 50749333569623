import { matchPath, match } from 'react-router-dom';
import { UserProfilePage } from '../../access';
import { Imprint } from '../components/imprint/imprint';
import { Policy } from '../components/policy/policy';
import { KnownRoutes } from './knownroutes';
import { KnownRoles } from './knownroles';
import RootStore from '../stores/rootStore';
import E404Component from '../components/errorPage/40x/e404';
import { E403Component } from '../components/errorPage/40x/e403';
import E401Component from '../components/errorPage/40x/e401';
import { SearchPage } from '../../search';
import { FirmwareAreaPage } from '../../firmware';
import { defaultLogger } from '../../../logger';
import { IPortalAuthorizationState } from '../user/portalAuthorization';
import { KnownRelationshipsLegacy } from './knownrelationships';
import { AocDevicePage } from '../../aoc/details/aocDevicePage';
import { DexitDevicePage } from '../../dexit/deviceSettings/dexitDevicePage';
import { HyenDevicePage } from '../../hyen/deviceSettings/hyenDevicePage';
import { ComfionDevicePage } from '../../comfion/deviceSettings/comfionDevicePage';
import { DeviceType } from '../../devices/stores/deviceStore';
import PreLogin from '../components/prelogin/prelogin';

export interface IAuthorizationResult {
    isValid: boolean;
    redirectLocation: string;
}

export interface IRouteDefinition {
    path: string;
    deviceType: DeviceType;
    exact?: boolean;
    component: any;
    menu: boolean;
    public?: boolean;
    menutextid?: string;
    roles?: string[];
    relationships?: KnownRelationshipsLegacy[];
    initialData?: (store: RootStore, params?: any) => Promise<any>;
}

class RoutingService {
    public static authorizeRoute(location: string, authorization: IPortalAuthorizationState): IAuthorizationResult {
        let redirectLocation = location;
        let isValid = false;

        const activeRoute = this.getAllRoutes()
            .map((routeDefinition) => {
                const reqRoute = location;
                const matched: match | null = matchPath(reqRoute, routeDefinition);
                return { routeDefinition, matched };
            })
            .find((route) => {
                return route.matched != null;
            });

        if (activeRoute) {
            location = activeRoute.routeDefinition.path;
        }

        if (!authorization.isAuthenticated) {
            const publicpathes = this.getPublicRoutes().map((r) => r.path);
            const locationIsPublic = publicpathes.find((paths) => paths === location) !== undefined;
            isValid = locationIsPublic;
            if (!isValid) {
                redirectLocation = KnownRoutes.Login;
            }
        } else if (location !== KnownRoutes.Search && !this.canNavigateToRoute(location, authorization)) {
            isValid = false;
            redirectLocation = KnownRoutes.Search;
        } else {
            isValid = true;
        }
        defaultLogger.info('-> redirect location ' + redirectLocation + ' is valid ' + isValid);
        return { isValid, redirectLocation };
    }

    public static canNavigateToRoute(location: KnownRoutes, authorization: IPortalAuthorizationState): boolean {
        const naviRoutes = this.getNavigatableRoutes(authorization);
        const route = naviRoutes.find((x) => x.path === location);
        return route !== undefined;
    }

    public static getNavigatableRoutes(authorization: IPortalAuthorizationState): IRouteDefinition[] {
        const routes: IRouteDefinition[] = [];
        const allRoutes = this.getAllRoutes();
        routes.push(...this.getPublicRoutes());

        if (this.hasAllowedUserRole(authorization) === true) {
            if (authorization.userIsVerified) {
                const homeRoute = this.getHomeRoute(authorization.roles);
                routes.push(homeRoute);

                // Filters all routes to return: public routes, routes allowed by my role and routes allowed by my relationship
                allRoutes
                    .filter(
                        (route) =>
                            !route.public &&
                            (!route.roles || route.roles.filter((role) => authorization.roles.includes(role)).length > 0) &&
                            (!route.relationships || route.relationships.filter((relationship) => authorization.relationship.includes(relationship as KnownRelationshipsLegacy)).length > 0)
                    )
                    .forEach((route) => {
                        if (route.component === homeRoute.component) {
                            route.menu = false;
                        }
                        routes.push(route);
                    });
            }

            routes.push({
                exact: false,
                path: KnownRoutes.All,
                component: E404Component,
                menu: false,
                public: false,
                deviceType: DeviceType.Unknown
            });
            return routes;
        } else {
            routes.push({
                exact: false,
                path: KnownRoutes.All,
                component: E403Component,
                menu: false,
                public: false,
                deviceType: DeviceType.Unknown
            });
            return routes;
        }
    }

    public static hasAllowedUserRole(authorization: IPortalAuthorizationState): boolean {
        if (authorization.roles.find((r) => [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.ProductManager, KnownRoles.Operator].includes(r))) {
            return true;
        }
        return false;
    }

    public static getRoute(path: KnownRoutes): IRouteDefinition {
        return RoutingService.getAllRoutes().find((route) => route.path === path)!;
    }

    public static getPublicRoutes(): IRouteDefinition[] {
        return RoutingService.getAllRoutes().filter((x) => x.public === true);
    }

    public static getCustomHomeRoutePath(roles: string[]): KnownRoutes | null {
        if (roles && (roles.includes(KnownRoles.TechnicalSupport) || roles.includes(KnownRoles.Reseller) || roles.includes(KnownRoles.Operator))) {
            return KnownRoutes.Search;
        } else if (roles && roles.includes(KnownRoles.ProductManager)) {
            return KnownRoutes.FirmwareOverview;
        }
        return null;
    }

    private static getHomeRoute(roles: string[]): IRouteDefinition {
        const allRoutes = this.getAllRoutes();

        let homeRoute: IRouteDefinition | undefined;

        const homePath = this.getCustomHomeRoutePath(roles);

        if (homePath !== null) {
            homeRoute = allRoutes.find((route) => route.path === homePath);
        }

        if (homeRoute) {
            if (roles && roles.every((role) => role === KnownRoles.ProductManager)) {
                homeRoute = this.getHomeRouteForProductManager();
                return homeRoute;
            }

            homeRoute.exact = true;
            homeRoute.path = KnownRoutes.Search;
            homeRoute.menu = true;
            homeRoute.menutextid = 'Menu.Home';
        } else {
            homeRoute = {
                exact: true,
                path: KnownRoutes.Search,
                component: SearchPage,
                menu: true,
                menutextid: 'Menu.Home',
                deviceType: DeviceType.Unknown
            };
        }

        return homeRoute;
    }

    private static getHomeRouteForProductManager(): IRouteDefinition {
        return {
            exact: true,
            path: KnownRoutes.FirmwareOverview,
            component: FirmwareAreaPage,
            menu: true,
            menutextid: 'Menu.Firmware',
            deviceType: DeviceType.Unknown
        };
    }

    private static getAllRoutes(): IRouteDefinition[] {
        const allRoutes: IRouteDefinition[] = [];
        allRoutes.push({
            exact: true,
            path: KnownRoutes.Imprint,
            component: Imprint,
            menu: false,
            public: true,
            deviceType: DeviceType.Unknown
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.Policy,
            component: Policy,
            menu: false,
            public: true,
            deviceType: DeviceType.Unknown
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.Default,
            component: PreLogin,
            menu: false,
            public: true,
            deviceType: DeviceType.Unknown
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.UserProfile,
            component: UserProfilePage,
            menu: true,
            menutextid: 'Menu.Profil',
            relationships: [KnownRelationshipsLegacy.External],
            deviceType: DeviceType.Unknown
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.Company,
            component: UserProfilePage,
            menu: false,
            relationships: [KnownRelationshipsLegacy.External],
            roles: [KnownRoles.Reseller],
            deviceType: DeviceType.Unknown
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.CompanyMembers,
            component: UserProfilePage,
            roles: [KnownRoles.Reseller],
            menu: false,
            deviceType: DeviceType.Unknown
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.AocDevice,
            component: AocDevicePage,
            menu: false,
            roles: [KnownRoles.TechnicalSupport, KnownRoles.ProductManager, KnownRoles.Operator],
            deviceType: DeviceType.Aoc
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.AocDeviceSettings,
            component: AocDevicePage,
            menu: false,
            roles: [KnownRoles.TechnicalSupport, KnownRoles.ProductManager, KnownRoles.Operator],
            deviceType: DeviceType.Aoc
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.AocDeviceNotes,
            component: AocDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller],
            deviceType: DeviceType.Aoc
        });

        // DEXIT
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitNotificationSettings,
            component: DexitDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.Operator],
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitDeviceSettings,
            component: DexitDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.Operator],
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitDeviceComponents,
            component: DexitDevicePage,
            menu: false,
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitDevice,
            component: DexitDevicePage,
            menu: false,
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitWLXManager,
            component: DexitDevicePage,
            menu: false,
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitOperatorAdministratorPairings,
            component: DexitDevicePage,
            menu: false,
            deviceType: DeviceType.Dexit
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.DexitDeviceNotes,
            component: DexitDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller],
            deviceType: DeviceType.Dexit
        });

        // Hyen
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDeviceLogs,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDeviceAppPairings,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDeviceRemoteConnect,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDevice,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenNotificationSettings,
            component: HyenDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.Operator],
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDeviceSettings,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenOperatorAdministratorPairings,
            component: HyenDevicePage,
            menu: false,
            deviceType: DeviceType.Hyen
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.HyenDeviceNotes,
            component: HyenDevicePage,
            menu: false,
            roles: [KnownRoles.Reseller],
            deviceType: DeviceType.Hyen
        });

        // Comfion
        allRoutes.push({
            exact: true,
            path: KnownRoutes.ComfionDevice,
            component: ComfionDevicePage,
            menu: false,
            deviceType: DeviceType.Comfion
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.ComfionDeviceRemoteConnect,
            component: ComfionDevicePage,
            menu: false,
            deviceType: DeviceType.Comfion
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.ComfionNotificationSettings,
            component: ComfionDevicePage,
            menu: false,
            deviceType: DeviceType.Comfion
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.ComfionDeviceSettings,
            roles: [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.Operator],
            component: ComfionDevicePage,
            menu: false,
            deviceType: DeviceType.Comfion
        });
        allRoutes.push({
            exact: true,
            path: KnownRoutes.ComfionDeviceNotes,
            roles: [KnownRoles.Reseller],
            component: ComfionDevicePage,
            menu: false,
            deviceType: DeviceType.Comfion
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.Search,
            component: SearchPage,
            menutextid: 'Menu.Search',
            menu: true,
            roles: [KnownRoles.Reseller, KnownRoles.TechnicalSupport, KnownRoles.Operator],
            deviceType: DeviceType.Unknown
        });

        allRoutes.push({
            exact: true,
            path: KnownRoutes.Search,
            component: SearchPage,
            menutextid: 'Menu.Home',
            menu: true,
            roles: [KnownRoles.ProductManager, KnownRoles.Operator],
            deviceType: DeviceType.Unknown
        });

        // Firmware
        allRoutes.push({
            exact: true,
            path: KnownRoutes.FirmwareOverview,
            component: FirmwareAreaPage,
            menu: true,
            menutextid: 'Menu.Firmware',
            roles: [KnownRoles.ProductManager, KnownRoles.TechnicalSupport],
            deviceType: DeviceType.Unknown
        });

        // Errorpages
        allRoutes.push({
            exact: false,
            path: KnownRoutes.Unauthorized,
            component: E401Component,
            menu: false,
            public: true,
            deviceType: DeviceType.Unknown
        });
        allRoutes.push({
            exact: false,
            path: KnownRoutes.AccessDenied,
            component: E403Component,
            menu: false,
            public: true,
            deviceType: DeviceType.Unknown
        });

        return allRoutes;
    }
}

export default RoutingService;
